<template>
    <div class="OnePage page">
        <AboutUs />
        <JFI />
        <!--
        <p @click="setBgClass('orange')">Orange</p>
        <p @click="setBgClass('night')">Night</p>
        -->
        <SalesMakers />
        <Recruitment />
        <TheFoxDen />
        <LetsConnect />
    </div>
</template>

<script>
    import store from '@/store';

    export default {
        name: 'Discover',
        components: {
            AboutUs: () => import('@/components/AboutUs'),
            JFI: () => import('@/components/JFI'),
            SalesMakers: () => import('@/components/SalesMakers'),
            Recruitment: () => import('@/components/Recruitment'),
            TheFoxDen: () => import('@/components/TheFoxDen'),
            LetsConnect: () => import('@/components/LetsConnect')
        },
        methods: {
            setBgClass: function (className) {
                store.dispatch('setBgClass', className);
            }
        }
    };
</script>

